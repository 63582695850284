import Interceptor from "./Interceptor";
import { API_URI_BASE } from "../config";
import axios from "axios";

/**
 * @typedef FileResponse
 * @type {object}
 * @property {string} filename Name of the file
 **/

/**
 * Upload a file
 * @param {FormData} data FormData to upload
 * @return {AxiosPromise}
 **/
const uploadFile = (data) => {
  let config = {
    method: "post",
    url: `${API_URI_BASE}/upload`,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };

  return Interceptor(config);
};

/**
 * Create a new submission
 * @param {object} data Payload
 * @return {AxiosPromise}
 **/
const createSubmission = (data) => {
  let config = {
    method: "post",
    url: `${API_URI_BASE}/submission`,
    data: data,
  };

  return Interceptor(config);
};

/**
 * Create verification
 * @param {string} mail
 * @return {AxiosPromise}
 **/
const createVerification = (mail) => {
  let params = new URLSearchParams();
  params.set("mail", mail);
  let config = {
    method: "post",
    url: `${API_URI_BASE}/verification`,
    params: params,
  };

  return Interceptor(config);
};

/**
 * Validate verification
 * @param {string} mail
 * @param {string} code
 * @return {AxiosPromise}
 **/
const validateVerification = (mail, code) => {
  let params = new URLSearchParams();
  params.set("verification_code", code);
  params.set("mail", mail);
  let config = {
    method: "get",
    url: `${API_URI_BASE}/verification`,
    params: params,
  };

  return Interceptor(config);
};

/**
 * Get the IP-Address of a user
 **/
const getIPAddress = () => {
  let config = {
    method: "get",
    url: "https://api.ipify.org?format=json",
  };
  return axios(config);
};

export {
  uploadFile,
  createSubmission,
  getIPAddress,
  createVerification,
  validateVerification,
};
