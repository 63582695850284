import axios from "axios";
import { CLIENT_SECRET } from "../config";

const Interceptor = axios.create();

Interceptor.interceptors.request.use((req) => {
  req.headers = { "X-API-KEY": `${CLIENT_SECRET}` };
  return req;
});

export default Interceptor;
