const emailCheckMixin = {
  data() {
    return {
      isFailedEmailCheck: false,
      isVerificationButtonDisabled: true,
    };
  },
  methods: {
    checkEmailAddress(str) {
      let regexRemax = /^\w+([-+.']\w+)*@?(remax.de|remax.com)$/;
      let regexDGVO = /^\w+([-+.']\w+)*@?(dgvo.de)$/;
      let regexAifinity = /^\w+([-+.']\w+)*@?(aifinity.io)$/;
      let regexSichtbarkeitsmeister =
        /^\w+([-+.']\w+)*@?(sichtbarkeitsmeister.de)$/;
      this.isFailedEmailCheck =
        !regexRemax.test(str.trim()) &&
        !regexDGVO.test(str.trim()) &&
        !regexAifinity.test(str.trim()) &&
        !regexSichtbarkeitsmeister.test(str.trim());
      this.isVerificationButtonDisabled = !(
        regexRemax.test(str.trim()) ||
        regexDGVO.test(str.trim()) ||
        regexAifinity.test(str.trim()) ||
        regexSichtbarkeitsmeister.test(str.trim())
      );
    },
  },
};

export default emailCheckMixin;
