const successMixin = {
  data() {
    return {
      isSuccess: false,
      successMessage: null,
    };
  },
  methods: {
    toggleSuccess(msg) {
      this.successMessage = msg;
      this.isSuccess = !this.isSuccess;
    },
  },
};

export default successMixin;
