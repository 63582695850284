<template>
  <div class="wrapper">
    <div id="submission">
      <submission-form v-if="!createReport" />
      <div v-else>
        <md-empty-state
          md-icon="description"
          :md-label="reportType"
          md-description="Bitte klicken Sie auf den Button, um das Dokument herunterzuladen"
        >
          <md-button class="md-primary" @click="handleDownload"
            >Herunterladen</md-button
          >
        </md-empty-state>
      </div>
    </div>
  </div>
</template>

<script>
import SubmissionForm from "../components/SubmissionForm";
import PDFGenerator from "../services/pdf/pdfGenerator";

export default {
  name: "Submission",

  components: { SubmissionForm },
  data() {
    return {
      company_name: null,
      first_name: null,
      last_name: null,
      street: null,
      house_number: null,
      postal_code: null,
      location: null,
      date: null,
      selection: null,
      createReport: false,
      type: "",
    };
  },
  created() {
    this.handleCreate();
  },
  computed: {
    reportType() {
      if (!this.type) return "";
      else
        return this.type === "documentation"
          ? "Beratungsdokumentation"
          : "Maklervereinbarung";
    },
  },
  methods: {
    handleCreate() {
      let search = location.search.replaceAll("?", "").split("&");
      if (search.length > 0 && search[0] !== "") {
        this.toggleCreateReport();
        search.forEach((item) => {
          let i = item.split("=");
          this.$data[i[0]] = i[1]
            .replaceAll("%20", " ")
            .replaceAll("%C3%9F", "ß");
        });
      }
      if (this.date !== null) this.type = "agreement";
      else this.type = "documentation";
    },
    handleDownload() {
      if (this.type && this.type === "agreement")
        this.generateBrokerAgreement();
      else if (this.type && this.type === "documentation")
        this.generateConsultationDocumentation(this.selection);
      else console.log("Not defined");
    },
    toggleCreateReport() {
      this.createReport = !this.createReport;
    },
    getPDFGenerator() {
      return new PDFGenerator(
        this.company_name,
        this.first_name,
        this.last_name,
        this.street,
        this.house_number,
        this.postal_code,
        this.location
      );
    },
    generateBrokerAgreement() {
      let pdf = this.getPDFGenerator();
      let date = new Date(this.date);
      let dateStr = [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
      ].join(".");
      pdf.generateBrokerAgreement(dateStr);
    },
    generateConsultationDocumentation(selection) {
      let pdf = this.getPDFGenerator();
      pdf.generateConsultationDocumentation(selection);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/main";

.wrapper {
  padding: 16px;
}

#submission {
  background-color: white;
  border-radius: $radius;
  border: 1.5px solid $dark-blue;
  padding: $padding;
}
</style>
