const activeStepsMixin = {
  data() {
    return {
      activeStep: "first",
      first: false,
      second: false,
      third: false,
      fourth: false,
    };
  },
  methods: {
    setDone(done, nextStep) {
      this[done] = true;

      if (nextStep) this.activeStep = nextStep;
    },
  },
};

export default activeStepsMixin;
