const errorMixin = {
  data() {
    return {
      isError: false,
      errorMessage: null,
    };
  },
  methods: {
    toggleError(msg) {
      this.errorMessage = msg;
      this.isError = !this.isError;
    },
  },
};

export default errorMixin;
