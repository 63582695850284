import { jsPDF } from "jspdf";
import { pdfText } from "./text";

class PDFGenerator {
  /**
   * PDFGenerator for the "Maklervollmacht"
   * @param {string} companyName
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} street
   * @param {string} houseNumber
   * @param {string} postalCode
   * @param {string} location
   **/
  constructor(
    companyName,
    firstName,
    lastName,
    street,
    houseNumber,
    postalCode,
    location
  ) {
    this.companyName = companyName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.street = street;
    this.houseNumber = houseNumber;
    this.postalCode = postalCode;
    this.location = location;
  }

  generateBrokerAuthorization() {
    // New pdf document
    const doc = new jsPDF("p", "mm", [297, 210]);

    // Title
    doc
      .setFontSize(14)
      .setTextColor("#757575")
      .setFont(undefined, "bold")
      .text("Maklervollmacht", 25, 25);

    // Logo
    doc.addImage(pdfText.logo, "PNG", 165, 20, 25, 25);

    // Address
    doc
      .setFontSize(8)
      .setTextColor("#757575")
      .setFont(undefined, "normal")
      .text("zwischen", 25, 32.5);

    // Customer Information
    doc
      .setFontSize(10)
      .text(`Firmierung: ${this.companyName}`, 25, 40) // companyName
      .text(`Vorname: ${this.firstName}`, 25, 47.5) // firstName
      .text(`Nachname: ${this.lastName}`, 25, 55) // lastName
      .text(`Strasse: ${this.street}`, 25, 62.5) // street
      .text(`Hausnummer: ${this.houseNumber}`, 25, 70) // houseNumber
      .text(`Postleitzahl: ${this.postalCode}`, 25, 77.5) // postalCode
      .text(`Ort: ${this.location}`, 25, 85); // location

    // Client
    doc
      .setFontSize(8)
      .setFont(undefined, "italic")
      .text("-nachfolgend Auftraggeber (Mandant) genannt-", 25, 90);

    // And
    doc.setFont(undefined, "normal").text("und", 25, 97.5);

    // DGVO
    doc
      .setFontSize(10)
      .setFont(undefined, "bold")
      .text(
        "Deutsche Gesellschaft für Versicherungsoptimierung mbH & Co. KG",
        25,
        105
      );

    // DGVO Address
    doc
      .setFontSize(8)
      .setFont(undefined, "normal")
      .text("Adenauerplatz 5", 25, 110)
      .text("41061 Mönchengladbach", 25, 115);

    // Broker
    doc
      .setFont(undefined, "italic")
      .text("-nachfolgend Makler genannt-", 25, 120);

    // Text part one
    doc
      .setFontSize(9)
      .setFont(undefined, "normal")
      .text(
        pdfText.partOne.textOne +
          pdfText.partOne.textTwo +
          pdfText.partOne.textThree,
        25,
        127.5
      );

    // Bullet point one
    doc
      .text("1.", 25, 140)
      .text(
        pdfText.bulletPoint.one.textOne +
          pdfText.bulletPoint.one.textTwo +
          pdfText.bulletPoint.one.textThree,
        30,
        140
      );

    // Bullet point two
    doc
      .text("2.", 25, 152.75)
      .text(pdfText.bulletPoint.two.textOne, 30, 152.75);

    // Bullet point three
    doc
      .text("3.", 25, 157)
      .text(
        pdfText.bulletPoint.three.textOne + pdfText.bulletPoint.three.textTwo,
        30,
        157
      );

    // Bullet point four
    doc
      .text("4.", 25, 165.5)
      .text(
        pdfText.bulletPoint.four.textOne +
          pdfText.bulletPoint.four.textTwo +
          pdfText.bulletPoint.four.textThree,
        30,
        165.5
      );

    // Bullet point five
    doc
      .text("5.", 25, 178.25)
      .text(pdfText.bulletPoint.five.textOne, 30, 178.25);

    // Bullet point six
    doc.text("6.", 25, 182.5).text(pdfText.bulletPoint.six.textOne, 30, 182.5);

    // Bullet point seven
    doc
      .text("7.", 25, 186.75)
      .text(pdfText.bulletPoint.seven.textOne, 30, 186.75);

    // Bullet point eight
    doc
      .text("8.", 25, 191)
      .text(
        pdfText.bulletPoint.eight.textOne +
          pdfText.bulletPoint.eight.textTwo +
          pdfText.bulletPoint.eight.textThree,
        30,
        191
      );

    // Part two
    doc.text(
      pdfText.partTwo.textOne +
        pdfText.partTwo.textTwo +
        pdfText.partTwo.textThree,
      25,
      203.5
    );

    // Part three
    doc
      .setFont(undefined, "bold")
      .text(
        pdfText.partThree.textOne +
          pdfText.partThree.textTwo +
          pdfText.partThree.textThree,
        25,
        216
      );

    // Part four
    doc
      .setFont(undefined, "normal")
      .text(pdfText.partFour.textOne + pdfText.partFour.textTwo, 25, 228.5);

    // Signature
    doc
      .rect(25, 250, 90, 0.125)
      .text("Datum und Unterschrift des Mandanten", 25, 255);

    // Save the PDF-File
    doc.output("pdfobjectnewwindow", { filename: "Maklervollmacht" });
  }

  // Selected
  // 0 ==> Vermögensschadenshaftpflicht
  // 1 ==> Betriebshaftpflichtversicherung
  // 2 ==> Inhalts- und Ertragsausfallsversicherung
  generateConsultationDocumentation(selected) {
    // New pdf document
    const doc = new jsPDF("p", "mm", [297, 210]);

    // Logo DGVO
    doc.addImage(pdfText.logo, "PNG", 160, 20, 25, 25);

    // Logo REMAX
    doc.addImage(pdfText.remaxLogo, "PNG", 25, 20, 25, 25);

    // Customer Information
    doc
      .setFontSize(10)
      .text(`${this.companyName}`, 25, 52.5)
      .text(`${this.firstName} ${this.lastName}`, 25, 60)
      .text(`${this.street} ${this.houseNumber}`, 25, 67.5)
      .text(`${this.postalCode} ${this.location}`, 25, 75);

    // Title
    doc
      .setFontSize(14)
      .setTextColor("#757575")
      .setFont(undefined, "bold")
      .text("Beratungsdokumentation", 25, 85);

    // Reason of consultation
    doc
      .setFontSize(10)
      .setTextColor("#000000")
      .text("Anlass der Beratung - Kundenwunsch und Bedürfnisse", 25, 92.5);

    doc
      .setFont(undefined, "normal")
      .text(
        "Der Versicherungsnehmer wünscht folgende Versicherungsverträge abzuschließen:",
        25,
        100
      );

    doc
      .setFontSize(12)
      .setFont(undefined, "bold")
      .text("Vermögensschadenshaftpflichtversicherung", 35, 110)
      .text("Betriebshaftpflichtversicherung", 35, 120)
      .text("Inhalts- und Ertragsausfallverscherung", 35, 130);

    doc.rect(25, 106, 5, 5).rect(25, 116, 5, 5).rect(25, 126, 5, 5);

    // Vermögensschadenshaftpflicht ==> 0
    if (selected) {
      if (selected.includes("0"))
        doc.line(25, 106, 30, 111).line(25, 111, 30, 106);

      // Betriebshaftpflicht ==> 1
      if (selected.includes("1"))
        doc.line(25, 116, 30, 121).line(25, 121, 30, 116);

      // Inhaltsversicherung ==> 2
      if (selected.includes("2"))
        doc.line(25, 126, 30, 131).line(25, 131, 30, 126);
    }

    doc.line(25, 140, 150, 140);

    doc
      .setFontSize(10)
      .text("Kundenentscheidung / Rat und Begründung", 25, 145);

    const text =
      "Aus der beruflichen/gewerblichen Tätigkeit als Immobilienmakler/in sind Sie verschiedensten" +
      "\n" +
      "Haftungsrisiken ausgesetzt, vor deren finanziellen Folgen eine Betriebshaftpflichtversicherung" +
      "\n" +
      "bzw. eine Vermögensschadenhaftpflichtversicherung schützen kann. Soweit der Geschäftsbetrieb im" +
      "\n" +
      "Rahmen eines Bürobetriebes erfolgt, ist i.d.R. kaufmännische und technische Betriebseinrichtung" +
      "\n" +
      "vorhanden, die bei Beschädigung und/oder (Total-)verlust auch zu einer Betriebseinstellung bzw." +
      "\n" +
      "daraus resultierendem Ertragsausfall führen kann." +
      "\n" +
      "Entsprechende Produktinformationsblätter haben wir Ihnen zum Download bzw. Ausdruck im Rahmen" +
      "\n" +
      "des Antragsprozesses über unsere Kundenseite zugänglich gemacht." +
      "\n\n" +
      "Der Inhaber des Master-Franchise-Rechts „Remax“ hat ein Interesse daran, dass alle unter dem" +
      "\n" +
      "Markenauftritt „Remax“ in Deutschland tätigen Personen über einen sehr guten Versicherungsschutz" +
      "\n" +
      "verfügen. Insoweit wurden wir beauftragt, allen „Remax-Maklern“ den Zugang zu preislich und" +
      "\n" +
      "inhaltlich auf die Bedürfnisse eines Remax-Maklers abgestimmten Versicherungsschutz in Form von" +
      "\n" +
      "Rahmenvertragskonditionen zu ermöglichen, die Sie nunmehr nutzen wollen." +
      "\n\n" +
      "Im Sinne des § 60 Versicherungsvertragsgesetz haben wir den Versicherungsmarkt im Vorfeld untersucht" +
      "\n" +
      "und mit verschiedenen Versicherern über die Vertragskonditionen verhandelt, um ein" +
      "\n" +
      "marktüberdurchschnittliches Preis-/Leistungsverhältnis zu erzielen, was mit den von uns vorgeschlagenen" +
      "\n" +
      " Versicherern gelungen ist. Dieses Rahmenvertragsangebot möchten Sie für sich nutzen und haben uns" +
      "\n" +
      "entsprechend beauftragt, den Versicherungsschutz nach Ihren Vorgaben zu installieren. Auf eine" +
      "\n" +
      "einzelvertragliche Ausschreibung wird in diesen Fällen ausdrücklich verzichtet und Sie nehmen das" +
      "\n" +
      "Angebot zu den Ihnen bekannten Konditionen an.";

    doc.setFont(undefined, "normal").text(text, 25, 155);

    // Footer
    doc.line(25, 280, 185, 280);

    doc
      .setFontSize(6)
      .text(
        "Deutsche Gesellschaft für Versicherungsoptimierung mbH & Co. KG, Adenauerplatz 5, 41061 Mönchengladbach, Tel 02161-53 95 1-0, Fax 02161-53 95 1-50",
        105,
        285,
        "center"
      )
      .text(
        "HRA Mönchengladbach 7517 vertr. d. DGVO Verwaltung GmbH Geschäftsführer: René Feicks . Thomas Roggenkamp",
        105,
        288,
        "center"
      );

    doc.output("pdfobjectnewwindow", {
      filename: "Beratungsdokumentation",
    });
  }

  generateContactConsent() {
    // New pdf document
    const doc = new jsPDF("p", "mm", [297, 210]);

    // Title
    doc
      .setFontSize(14)
      .setTextColor("#757575")
      .setFont(undefined, "bold")
      .text("Einwilligungserklärung zur Kontaktaufnahme / Werbung", 25, 25);

    // Logo
    doc.addImage(pdfText.logo, "PNG", 165, 20, 25, 25);

    // Address
    doc
      .setFontSize(8)
      .setTextColor("#000000")
      .setFont(undefined, "normal")
      .text("zwischen", 25, 32.5);

    // Customer Information
    doc
      .setFontSize(10)
      .text(`Firmierung: ${this.companyName}`, 25, 40) // companyName
      .text(`Vorname: ${this.firstName}`, 25, 47.5) // firstName
      .text(`Nachname: ${this.lastName}`, 25, 55) // lastName
      .text(`Strasse: ${this.street}`, 25, 62.5) // street
      .text(`Hausnummer: ${this.houseNumber}`, 25, 70) // houseNumber
      .text(`Postleitzahl: ${this.postalCode}`, 25, 77.5) // postalCode
      .text(`Ort: ${this.location}`, 25, 85); // location

    // Client
    doc
      .setFontSize(8)
      .setFont(undefined, "italic")
      .text("-nachfolgend Auftraggeber (Mandant) genannt-", 25, 90);

    // And
    doc.setFont(undefined, "normal").text("und", 25, 97.5);

    // DGVO
    doc
      .setFontSize(10)
      .setFont(undefined, "bold")
      .text(
        "Deutsche Gesellschaft für Versicherungsoptimierung mbH & Co. KG",
        25,
        105
      );

    // DGVO Address
    doc
      .setFontSize(8)
      .setFont(undefined, "normal")
      .text("Adenauerplatz 5", 25, 110)
      .text("41061 Mönchengladbach", 25, 115);

    // Broker
    doc
      .setFont(undefined, "italic")
      .text("-nachfolgend Makler genannt-", 25, 120);

    const text =
      "Der Auftraggeber willigt ausdrücklich ein, dass ihn der Versicherungsmakler - auch über den Umfang der" +
      "\n" +
      "von ihm ggf. vermittelten und betreuten Versicherungsverträge hinaus - über Versicherungsprodukte" +
      "\n" +
      "informieren darf, zum Beispiel über den etwaigen Abschluss neuer Versicherungsverträge und/oder über" +
      "\n" +
      "inhaltliche Änderungen von bestehenden Verträgen, insbesondere deren Verlängerung, Ausweitung und/oder" +
      "\n" +
      "Ergänzung und zwar zusätzlich zum üblichen Briefverkehr auch per Telefon, Telefax und E-Mail." +
      "\n\n" +
      "Diese Einwilligung über die vorgenannten Kontaktmöglichkeiten kann formlos und ohne Angaben von" +
      "\n" +
      "Gründen vom Auftraggeber jederzeit teilweise oder vollständig widerrufen werden" +
      "\n\n" +
      "Ich bin damit einverstanden, dass die vom Versicherungsmakler erhobenen Daten zum oben genannten" +
      "\n" +
      "Zweck gespeichert, verarbeitet und genutzt werden dürfen. Die Daten werden nicht an Dritte weitergegeben.";

    // Text part one
    doc.setFontSize(9).setFont(undefined, "normal").text(text, 25, 127.5);

    doc.output("pdfobjectnewwindow", {
      filename: "Einwilligung-Kontaktaufnahme",
    });
  }

  generateBrokerAgreement(date) {
    // New pdf document
    const doc = new jsPDF("p", "mm", [297, 210]);

    // Logo
    doc.addImage(pdfText.logo, "PNG", 165, 20, 25, 25);

    // Title
    doc
      .setFontSize(16)
      .setTextColor("#757575")
      .setFont(undefined, "bold")
      .text("Versicherungsmaklervertrag", 25, 25);

    doc
      .setFontSize(8)
      .setFont(undefined, "normal")
      .setTextColor("#000000")
      .text("Zwischen", 25, 35);

    // Customer Information
    doc
      .setFontSize(10)
      .text(`${this.companyName}`, 25, 42.5)
      .text(`${this.firstName} ${this.lastName}`, 25, 50)
      .text(`${this.street} ${this.houseNumber}`, 25, 57.5)
      .text(`${this.postalCode} ${this.location}`, 25, 65);

    doc
      .setFontSize(8)
      .setFont(undefined, "italic")
      .text("-nachfolgend Auftraggeber (Mandant) genannt-", 25, 72.5);

    doc.setFont(undefined, "normal").text("und", 25, 82.5);

    doc
      .setFontSize(10)
      .setFont(undefined, "bold")
      .text(
        "Deutsche Gesellschaft für Versicherungsoptimierung mbH & Co. KG",
        25,
        92.5
      );

    doc
      .setFont(undefined, "normal")
      .text("Adenauerplatz 5", 25, 100)
      .text("41061 Mönchengladbach", 25, 107.5)
      .setFont(undefined, "bold")
      .text("T", 25, 115)
      .setFont(undefined, "normal")
      .text("+49 (0)2161 / 53 95 1-0", 30, 115)
      .setFont(undefined, "bold")
      .text("M", 25, 122.5)
      .setFont(undefined, "normal")
      .text("info@dgvo.de", 30, 122.5)
      .setFontSize(8)
      .setFont(undefined, "italic")
      .text("-nachfolgend Makler genannt-", 25, 130);

    // First Page
    doc.addPage([297, 210], "p");

    doc
      .setFontSize(8)
      .setFont(undefined, "normal")
      .text(
        "Mit dem Tag der Zustimmung im Rahmen des Fernabsatzes wird folgender Versicherungsmaklervertrag geschlossen:",
        25,
        25
      );

    // I
    const paragraphOneOne =
      "\tDer Auftraggeber beauftragt den Versicherungsmakler und einen eventuellen Rechtsnachfolger mit der Vermittlung" +
      "\n" +
      "\tvon Versicherungsverträgen. Der Versicherungs- makler bereitet den Abschluss von Versicherungsverträgen vor und" +
      "\n" +
      "\terbringt zudem Verwaltungs- und Betreuungsleistungen nach Abschluss dieser Verträge.";

    const paragraphOneTwo =
      "\tDie Verwaltungs- und Betreuungsleistungen nach der Vermittlung erstrecken sich auch auf vom Versicherungsmakler" +
      "\n" +
      "\tin Abstimmung mit dem Auftraggeber ausdrücklich in seine Betreuungübernommene Versicherungsverhältnisse, die" +
      "\n" +
      "\tbereits vor Beginn dieses Vertrages für den Auftraggeber bestanden. Im Einzelfall können Verwaltungshemmnisse" +
      "\n" +
      "\tauf Seiten der jeweiligen Versicherer dem entgegenstehen. Die Betreuung von gesetzlichen Sozialversicherungen" +
      "\n" +
      "\tist ausgeschlossen.";

    const paragraphOneThree =
      "\tDer Auftraggeber beauftragt den Versicherungsmakler ebenfalls zur Erteilung von SEPA-Lastschriftmandanten" +
      "\n" +
      "\tfür den Auftraggeber an die jeweiligen Versicherer nach seiner Weisung zu erteilen. Der Versicherungsmakler macht" +
      "\n" +
      "\thiervon nur in den Fällen Gebrauch, in denen der Auftraggeber die Abbuchung der Beiträge/ Prämien durch den" +
      "\n" +
      "\tVersicherer wünscht.";

    const paragraphTwoOne =
      "\tDer Versicherungsmakler erfüllt seine Pflichten in Übereinstimmung mit den Bestimmungen des Versicherungsvertrags-" +
      "\n" +
      "\tgesetzes (§§ 59 ff.) mit der Sorgfalt eines ordentlichen Kaufmannes.";

    const paragraphTwoTwo =
      "\tDer Versicherungsmakler übernimmt durch diesen Vertrag insbesondere folgende Aufgaben:";

    const paragraphTwoThree =
      "\tDer Versicherungsmakler legt seinem Rat regelmäßig eine hinreichende Zahl von auf dem Markt angebotenen Deckungs-" +
      "\n" +
      "\tkonzepten bzw. von Versicherern zugrunde, es sei denn, er weist ausdrücklich auf eine eingeschränkte Auswahl hin.";

    const paragraphTwoFour =
      "\tAuswahlkriterien sind in erster Linie die jeweils gebotene Leistung, der Preis, die Sicherheit des Anbieters," +
      "\n" +
      "\tdie Verfügbarkeit, die Art und Weise der Schadensabwicklung, sowie der Ablauf der Geschäftsprozesse der" +
      "\n" +
      "\tVersicherungsunter-nehmen. Bei seiner Auswahl berücksichtigt der Versicherungsmakler nur Versicherer," +
      "\n" +
      "\tdie der Kontrolle der Bundesanstalt für Finanzdienstleistungsaufsicht(BaFin) unterliegen.";

    const paragraphTwoFive =
      "\tSofern die Art der Risiken oder die Marktverhältnisse es sinnvoll machen, ist es dem Versicherungsmakler" +
      "\n" +
      "\tfreigestellt, Versicherungen auch an im Dienstleistungsverkehr tätige Versicherer zu vermitteln." +
      "\n" +
      "\tEine rechtliche Verpflichtung hierzu besteht allerdings nicht.";

    const paragraphTwoSix =
      "\tVersicherungen werden nicht an Direkt- versicherer oder Unternehmen vermittelt, die dem Makler keine" +
      "\n" +
      "\tVergütung gewähren.";

    doc
      .setFont(undefined, "bold")
      .text("(1)\tVertragsgegenstand", 25, 35)
      .setFont(undefined, "normal")
      .text("1.", 30, 42.5)
      .text(paragraphOneOne, 30, 42.5)
      .text("2.", 30, 55)
      .text(paragraphOneTwo, 30, 55)
      .text("3.", 30, 75)
      .text(paragraphOneThree, 30, 75)
      .setFont(undefined, "bold")
      .text("(2)\tVersicherungsmakler", 25, 95)
      .setFont(undefined, "normal")
      .text(
        "Der Versicherungsmakler ist an keine Versicherungsgesellschaft gebunden, also unabhängiger Kaufmann." +
          "\n" +
          "Er kann daher die Interessen des Auftraggebers neutral vertreten.",
        25,
        102.5
      )
      .text("1.", 30, 112.5)
      .text(paragraphTwoOne, 30, 112.5)
      .text("2.", 30, 122.5)
      .text(paragraphTwoTwo, 30, 122.5)
      .text(
        "\t·\tPrüfung des Versicherungsbedarfs unter Berücksichtigung der Wünsche und Bedürfnisse des Auftraggebers.",
        30,
        130
      )
      .text(
        "\t·\tVermittlung der nach Absprache mit dem Auftraggeber für notwendig erachteten Versicherungsverträge" +
          "\n" +
          "\t \tan den Versicherer, der das spezifische Risiko dauerhaft unter Berücksichtigung der nachfolgenden" +
          "\n" +
          "\t \tAuswahlkriterien versichert.",
        30,
        137.5
      )
      .text(
        "\t·\tÜberwachung und laufende Betreuung der Versicherung und nach Abstimmung mit dem Auftraggeber" +
          "\n" +
          "\t \tdie Anpassung des Versicherungsschutzes an veränderte Risiko- und Marktverhältnisse.",
        30,
        150
      )
      .text(
        "\t·\tIm Schandensfall die Unterstützung des Auftraggebers bei der Regulierung einschließlich" +
          "\n" +
          "\t \tder Verhandlung mit dem Versicherer bis zu Entschädigung",
        30,
        158
      )
      .text("3.", 30, 168)
      .text(paragraphTwoThree, 30, 168)
      .text("4.", 30, 178)
      .text(paragraphTwoFour, 30, 178)
      .text("5.", 30, 195)
      .text(paragraphTwoFive, 30, 195)
      .text("6.", 30, 210)
      .text(paragraphTwoSix, 30, 210);

    const paragraphThreeOne =
      "Die Leistungen des Versicherungsmaklers werden durch die von den Versicherungsgesellschaften gezahlten Courtagen" +
      "\n" +
      "abgegolten. Durch die Beauftragung des Versicherungsmaklers entstehen daher dem Auftraggeber keine zusätzlichen" +
      "\n" +
      "Kosten, sofern keine anderweitige Vereinbarung getroffen wird";

    // Paragraph 3
    doc
      .setFont(undefined, "bold")
      .text("(3)\tVergütung", 25, 225)
      .setFont(undefined, "normal")
      .text(paragraphThreeOne, 25, 232.5);

    const paragraphFourOne =
      "1.\tDer Auftraggeber kann den Versicherungs-maklervertrag jederzeit kündigen.";
    const paragraphFourTwo =
      "2.\tSofern kein wichtiger Grund vorliegt, darf der Versicherungsmakler nur derart kündigen, dass der Auftraggeber" +
      "\n" +
      "   \tdie Dienste anderweitig beschaffen kann, längstens gilt aber eine Kündigungsfrist von einem Monat.";

    // Paragraph 4
    doc
      .setFont(undefined, "bold")
      .text("(4)\tKündigung", 25, 250)
      .setFont(undefined, "normal")
      .text(paragraphFourOne, 25, 257.5)
      .text(paragraphFourTwo, 25, 262.5);

    // Paragraph 5
    doc
      .setFont(undefined, "bold")
      .text("(5)\tPflichten des Auftraggebers", 25, 275)
      .setFont(undefined, "normal")
      .text(
        "Vertrags- und risikorelevante Änderungen oder Neuerungen hat der Auftraggeber dem Makler unverzüglich mitzuteilen.",
        25,
        282.5
      );

    // Second Page
    doc.addPage([297, 210], "p");

    const sixOne =
      "1.\tDie Haftung des Maklers ist im Falle leicht fahrlässiger Verletzung seiner vertraglichen Pflichten auf" +
      "\n" +
      "   \teinen Betrag von 3.000.000,00 Euro je Schadenfall begrenzt. Der Versicherungsmakler hält mindestens bis zu dieser" +
      "\n" +
      "   \tSumme eine Vermögensschadenhaftpflichtversicherung vor.";

    const sixTwo =
      "2.\tSoweit im Einzelfall das Risiko eines höheren Schadens besteht, hat der Auftraggeber die Möglichkeit, den" +
      "\n" +
      "   \tHaftpflicht- Versicherungsschutz des Maklers auf eigene Kosten auf eine Versicherungssumme zu erhöhen, die das" +
      "\n" +
      "   \tübernommene Risiko abdeckt; der Makler gibt hierzu eine Empfehlung ab.";

    const sixThree =
      "3.\tAnsprüche auf Schadenersatz verjähren in 3 Jahren. Die Verjährung beginnt mit dem Schluss des Jahres, in dem" +
      "\n" +
      "   \tder Anspruch entstanden ist und der Kunde Kenntnis von den Anspruch begründeten Umständen und der Person des" +
      "\n" +
      "   \tErsatzpflichtigen erlangt hat oder ohne grobe Fahrlässigkeit hätte erlangen müssen. Spätestens verjähren Ansprüche" +
      "\n" +
      "   \tjedoch nach 5 Jahren beginnend mit dem Schluss des Jahres, in welchem der Maklervertrag beendet wurde.";

    // Paragraph 6
    doc
      .setFontSize(8)
      .setFont(undefined, "bold")
      .text("(6)\tHaftung", 25, 25)
      .setFont(undefined, "normal")
      .text(sixOne, 25, 32.5)
      .text(sixTwo, 25, 45)
      .text(sixThree, 25, 57.5);

    const sevenOne =
      "Die Vertretungsbefugnisse gegenüber Dritten ergeben sich aus der dem Versicherungsmakler erteilten Vollmacht, die vom" +
      "\n" +
      "Auftraggeber in einer gesonderten Urkunde erteilt wird und dem Versicherungs-maklervertrag beiliegt.";

    // Paragraph 7
    doc
      .setFont(undefined, "bold")
      .text("(7)\tVertretunsvollmacht", 25, 77.5)
      .setFont(undefined, "normal")
      .text(sevenOne, 25, 85);

    const eigthOne =
      "Der Geschäftsverkehr bedingt die Kontaktaufnahme mit dem Auftraggeber sowie den Austausch von Daten und Informationen." +
      "\n" +
      "Der Auftraggeber willigt in die als Anlagen beigefügte Einwilligungserklärungen zum Bundesdatenschutzgesetz und zur" +
      "\n" +
      "Kommunikation ein.";

    // Paragraph 8
    doc
      .setFont(undefined, "bold")
      .text("(8)\tDatenschutz & Kommunikation", 25, 100)
      .setFont(undefined, "normal")
      .text(eigthOne, 25, 107.5);

    const nineOne =
      "1.\tÄnderungen und Ergänzungen dieses Vertrages bedürfen der Schriftform.";

    const nineTwo =
      "2.\tSollte eine Vorschrift dieses Vertrages unwirksam sein oder durch die Rechtsprechung oder durch gesetzliche Regelungen" +
      "\n" +
      "   \tunwirksam werden, so hat dies nicht die Unwirksamkeit des gesamten Vertrages zur Folge. Die nichtige Bestimmung ist durch" +
      "\n" +
      "   \teine Regelung zu ersetzen, die dem angestrebten Zweck am ehesten entspricht.";

    const nineThree =
      "3.\tGerichtsstand für alle Ansprüche aus diesem Vertrag ist München.";

    // Paragraph 9
    doc
      .setFont(undefined, "bold")
      .text("(9)\tSchlussbestimmungen", 25, 122.5)
      .setFont(undefined, "normal")
      .text(nineOne, 25, 130)
      .text(nineTwo, 25, 137.5)
      .text(nineThree, 25, 150);

    // Maklervollmacht
    doc.addPage([297, 210], "p");

    doc
      .setFontSize(16)
      .setTextColor("#757575")
      .setFont(undefined, "bold")
      .text("Maklervollmacht", 25, 25);

    const maklerStart =
      "Der Auftraggeber bevollmächtigt den Versicherungsmakler und einen eventuellen Rechtsnachfolger zur Regelung seiner" +
      "\n" +
      "Versicherungsverhältnisse, zur Betreuung seiner Versicherungsangelegenheiten sowie zur Beschaffung des erforderlichen" +
      "\n" +
      "Versicherungsschutzes. Diese Vollmacht umfasst insbesondere";

    // Logo
    doc.addImage(pdfText.logo, "PNG", 165, 20, 25, 25);

    const maklerFirst =
      "1.\tdie uneingeschränkte aktive und passive Vertretung des Auftraggebers gegenüber den jeweiligen Versicherern" +
      "\n" +
      "   \teinschließlich der Abgabe und Entgegennahme aller die vermittelten und/oder betreuten Versicherungsverträge" +
      "\n" +
      "   \tbetreffenden Willenserklärungen, Anzeigen und Vertrags- informationen.";

    const maklerSecond =
      "2.\tdie Kündigung bestehender und den Abschluss neuer Versicherungsverträge.";

    const maklerThird =
      "3.\tdie Geltendmachung der Versicherungsleistungen aus vom Makler vermittelten bzw. betreuten Versicherungs-" +
      "\n" +
      "   \tverhältnissen und die sonstige Mitwirkung bei der Schadensregulierung.";

    const maklerFourth =
      "4.\tdie Entgegennahme von Zahlungen des Auftraggebers. Regelmäßig bestehen Inkasso- vollmachten der Versicherer" +
      "\n" +
      "   \tgegenüber dem Makler. Soweit eine solche Inkassovollmacht erteilt wurde, hat die Zahlung des Auftraggebers" +
      "\n" +
      "   \tan den Makler bereits befreiende Wirkung gegenüber solchen Versicherern.";

    const maklerFifth =
      "5.\tdie Erteilung von Untervollmachten an andere Versicherungsmakler.";

    const maklerSixth =
      "6.\tAuskünfte von Sozialversicherungsträgern einzuholen.";

    const maklerSeventh =
      "7.\tdie Durchführung von Beschwerdeverfahren beim Ombudsmann im Namen des Versicherungsnehmers.";

    const maklerEighth =
      "8.\tDiese Vollmacht umfasst insbesondere die Erteilung und den Widerruf von SEPA- Lastschriftmandaten sowie die" +
      "\n" +
      "   \tVertretung gegenüber der Hausbank des Auftraggebers im Hinblick auf die Anweisung gegenüber der Hausbank, die" +
      "\n" +
      "   \tjeweiligen Beträge vom Konto des Auftraggebers als Lastschrift abzubuchen.";

    doc
      .setFontSize(8)
      .setFont(undefined, "normal")
      .setTextColor("#000000")
      .text(maklerStart, 25, 55)
      .text(maklerFirst, 25, 72.5)
      .text(maklerSecond, 25, 85)
      .text(maklerThird, 25, 92.5)
      .text(maklerFourth, 25, 102.5)
      .text(maklerFifth, 25, 115)
      .text(maklerSixth, 25, 122.5)
      .text(maklerSeventh, 25, 130)
      .text(maklerEighth, 25, 137.5);

    // Schluss
    const endOne =
      "Der Auftraggeber weist die Versicherer ausdrücklich an, dem Makler auf dessen Verlangen uneingeschränkt Auskunft zu erteilen." +
      "\n" +
      "Steht der Auskunftserteilung gegebenenfalls eine Pflicht zur Verschwiegenheit entgegen, so wird der Betreffende von der" +
      "\n" +
      "Schweigepflicht hiermit ausdrücklich entbunden.";
    const endTwo =
      "Der Makler ist von den Beschränkungen des § 181 BGB befreit. Die Vollmacht ist zeitlich nicht befristet. Sie kann vom" +
      "\n" +
      "Auftraggeber jederzeit widerrufen werden.";
    const endThree =
      "Diese Maklervereinbarung wurde zwischen den o.g. Parteien (Mandant und Makler) im Rahmen des Fernabsatzes mittels" +
      "\n" +
      "verifizierter Email-Adresse bestätigt und am " +
      date +
      " geschlossen.";

    doc.text(endOne, 25, 155).text(endTwo, 25, 170).text(endThree, 25, 180);

    console.log(date);

    doc.output("pdfobjectnewwindow", {
      filename: "Maklervereinbarung",
    });
  }
}

export default PDFGenerator;
