const disabledDatesMixin = {
  data() {
    return {
      disabledDates: (date) => {
        return date < Date.now();
      },
    };
  },
};

export default disabledDatesMixin;
