<!-- prettier-ignore -->
<template>
  <div class="rel-container">
    <md-progress-spinner
        :md-diameter="100"
        :md-stroke="5"
        md-mode="indeterminate"
        class="spinner-adjuster"
        v-if="loading"
    />

    <md-steppers :class="loading ? 'opacity-30' : ''" md-vertical md-linear :md-active-step.sync="activeStep">

      <md-step id="first" :md-done="first" md-label="Versicherungen">

        <p>
          Ich möchte per sofort (soweit eine Vorversicherung besteht wird prämienfrei bis zur nächsten
          Jahreshauptfälligkeit eine Summenkonditionsdeckung geboten, d.h. sollte Ihr bisheriger
          Versicherungsschutz Deckungselemente des künftigen Versicherungsschutzes nicht beinhalten,
          bietet Ihr neuer Vertrag hierfür bereits Deckung) von den Sonderkonditionen profitieren:
        </p>

        <!-- Vermögensschadenshaftpflicht-Versicherung -->
        <div class="insurance-wrapper">
          <p class="holder f-16">
            <strong>Bitte geben Sie an, ob Sie eine Vermögensschadenshaftpflicht-Versicherung wünschen:</strong>
          </p>

          <!-- Conditions -->
          <div>
            <md-button class="md-primary button-adjuster margin-0" href="/Versicherungsbedingungen.pdf" target="_blank">
              Versicherungsbedingungen herunterladen
            </md-button>
          </div>

          <!-- Vermögensschadenshaftpflicht-Versicherung -->
          <md-checkbox v-model="submission.assetLiability.insurance_type"
                       value="Vermögensschadenshaftpflicht-Versicherung">
            <strong>Ich wünsche eine Vermögensschadenshaftpflicht-Versicherung</strong>
          </md-checkbox>

          <!-- Insurance sum -->
          <div v-if="submission.assetLiability.insurance_type">

            <p class="bolder">Ich wünsche folgende Versicherungssumme für die
              Vermögensschadenshaftpflicht-Versicherung:</p>

            <!-- Insurance sum -->
<!--            <md-field :class="submission.assetLiability.insurance_sum === 0 && 'md-invalid'">-->
<!--              <label>Versicherungssumme</label>-->
<!--              <md-input type="number"-->
<!--                        required-->
<!--                        v-model="submission.assetLiability.insurance_sum"-->
<!--                        step="1000"-->
<!--                        @change="roundToThousands(submission.assetLiability.insurance_sum, 'asset')"-->
<!--              />-->
<!--              <span class="md-error">Bitte geben Sie die Versicherungssumme an.</span>-->
<!--            </md-field>-->
            <md-radio v-model="submission.assetLiability.insurance_sum" :value="250000">250.000 EUR</md-radio>
            <md-radio v-model="submission.assetLiability.insurance_sum" :value="500000">500.000 EUR</md-radio>
            <md-radio v-model="submission.assetLiability.insurance_sum" :value="1000000">1.000.000 EUR</md-radio>
            <md-radio v-model="submission.assetLiability.insurance_sum" :value="1500000">1.500.000 EUR</md-radio>

          </div>

          <!-- Insurance revenue -->
          <div v-if="submission.assetLiability.insurance_type">

            <p class="bolder">Bitte geben Sie Ihren Umsatz p.a. an</p>

            <!-- Insurance sum -->
            <md-radio v-model="submission.assetLiability.insurance_revenue" :value="500000">Bis 500.000 EUR</md-radio>
            <md-radio v-model="submission.assetLiability.insurance_revenue" :value="1000000">Bis 1.000.000 EUR
            </md-radio>
            <md-radio v-model="submission.assetLiability.insurance_revenue" :value="1000001">Mehr als 1.000.000 EUR
            </md-radio>

          </div>

          <!-- Calculation -->
          <div v-if="submission.assetLiability.insurance_type">
            <p>Die Prämie beträgt: {{ calculatePremiums() }}</p>
            <p>Prämien jeweils zzgl. z.Zt. 19 % Versicherungssteuer</p>
          </div>

          <!-- Previous insurance -->
          <div v-if="submission.assetLiability.insurance_type">

            <p class="holder">Besteht bereits eine Versicherung?</p>

            <md-radio v-model="submission.assetLiability.previous_insurance" :value="true">Ja</md-radio>
            <md-radio v-model="submission.assetLiability.previous_insurance" :value="false">Nein</md-radio>

            <md-datepicker v-model="submission.assetLiability.insurance_begin"
                           v-if="!submission.assetLiability.previous_insurance"
                           md-immediately
                           :md-disabled-dates="disabledDates"
            >
              <label>Gewünschter Vertragsbeginn *</label>
            </md-datepicker>

            <div v-if="submission.assetLiability.previous_insurance">

              <p class="holder">Sollen wir Ihren vorhergehenden Vertrag für Sie kündigen?</p>

              <md-radio v-model="submission.assetLiability.previous_insurance_terminate" :value="true">Ja</md-radio>
              <md-radio v-model="submission.assetLiability.previous_insurance_terminate" :value="false">Nein</md-radio>

              <div v-if="submission.assetLiability.previous_insurance_terminate">

                <md-field>
                  <label>Versicherungsgesellschaft</label>
                  <md-input v-model="submission.assetLiability.previous_insurance_company" required/>
                </md-field>

                <md-field>
                  <label>Vertragsnummer</label>
                  <md-input v-model="submission.assetLiability.previous_insurance_contract_number" required/>
                </md-field>

                <md-datepicker v-model="submission.assetLiability.previous_insurance_termination_date" md-immediately
                               :md-disabled-dates="disabledDates">
                  <label>Auslaufdatum der Versicherung *</label>
                </md-datepicker>

              </div>

            </div>
          </div>
        </div>

        <!-- Betriebshaftpflichtversicherung -->
        <div class="insurance-wrapper">
          <p class="holder f-16">
            <strong>Bitte geben Sie an, ob Sie eine Betriebshaftpflichtversicherung wünschen:</strong>
          </p>

          <!-- Conditions -->
          <div>
            <md-button class="md-primary button-adjuster margin-0" href="/Versicherungsbedingungen" target="_blank">
              Versicherungsbedingungen herunterladen
            </md-button>
          </div>

          <div v-if="!isAssetLiabilityActive">
            <p class="info-text">Die Betriebshaftpflichtversicherung ist nur in Verbindung mit der Vermögensschadenshaftpflicht-Versicherung möglich.</p>
          </div>

          <div>
            <p>
              Prämie p.a. 85 € zzgl. z.Zt. 19 % Versicherungssteuer
            </p>
          </div>

          <!-- Vermögensschadenshaftpflicht-Versicherung -->
          <md-checkbox :disabled="!isAssetLiabilityActive" v-model="submission.businessLiability.insurance_type" value="Betriebshaftpflichtversicherung">
            <strong>Ich wünsche eine Betriebshaftpflichtversicherung</strong>
          </md-checkbox>

          <!-- Insurance sum -->
          <div v-if="submission.businessLiability.insurance_type">

            <p class="bolder">Die Betriebshaftpflichtversicherung mit einer Versicherungssumme von 10.000.000 EUR
              pauschal für Personen- und Sachschäden.</p>

          </div>

          <!-- Previous insurance -->
          <div v-if="submission.businessLiability.insurance_type">

            <p class="holder">Besteht bereits eine Versicherung?</p>

            <md-radio v-model="submission.businessLiability.previous_insurance" :value="true">Ja</md-radio>
            <md-radio v-model="submission.businessLiability.previous_insurance" :value="false">Nein</md-radio>

            <md-datepicker v-model="submission.businessLiability.insurance_begin"
                           v-if="!submission.businessLiability.previous_insurance"
                           md-immediately
                           :md-disabled-dates="disabledDates"
            >
              <label>Gewünschter Vertragsbeginn *</label>
            </md-datepicker>

            <div v-if="submission.businessLiability.previous_insurance">

              <p class="holder">Sollen wir Ihren vorhergehenden Vertrag für Sie kündigen?</p>

              <md-radio v-model="submission.businessLiability.previous_insurance_terminate" :value="true">Ja</md-radio>
              <md-radio v-model="submission.businessLiability.previous_insurance_terminate" :value="false">Nein
              </md-radio>

              <div v-if="submission.businessLiability.previous_insurance_terminate">

                <md-field>
                  <label>Versicherungsgesellschaft</label>
                  <md-input v-model="submission.businessLiability.previous_insurance_company" required/>
                </md-field>

                <md-field>
                  <label>Vertragsnummer</label>
                  <md-input v-model="submission.businessLiability.previous_insurance_contract_number" required/>
                </md-field>

                <md-datepicker v-model="submission.businessLiability.previous_insurance_termination_date" md-immediately
                               :md-disabled-dates="disabledDates">
                  <label>Auslaufdatum der Versicherung *</label>
                </md-datepicker>

              </div>

            </div>
          </div>
        </div>

        <!-- Inhaltsversicherung mit Ertragsausfall -->
        <div class="insurance-wrapper">
          <p class="holder f-16">
            <strong>Bitte geben Sie an, ob Sie eine Inhaltsversicherung mit Ertragsausfall wünschen:</strong>
          </p>

          <!-- Conditions -->
          <div>
            <md-button class="md-primary button-adjuster margin-0" href="/Versicherungsbedingungen" target="_blank">
              Versicherungsbedingungen herunterladen
            </md-button>
          </div>

          <!-- Vermögensschadenshaftpflicht-Versicherung -->
          <md-checkbox v-model="submission.contentInsurance.insurance_type"
                       value="Inhaltsversicherung mit Ertragsausfall">
            <strong>Ich wünsche eine Inhaltsversicherung mit Ertragsausfall</strong>
          </md-checkbox>

          <!-- Insurance sum -->
          <div v-if="submission.contentInsurance.insurance_type">

            <p class="bolder">Ich wünsche folgende Versicherungssumme/Höchstentschädigung jeweils für die
              Inhaltsversichersicherung und den Ertragsausfall für die Inhaltsversicherung mit Ertragsausfall:</p>

            <!-- Insurance sum -->
            <md-field :class="submission.contentInsurance.insurance_sum === 0 && 'md-invalid'">
              <label>Versicherungssumme</label>
              <md-input type="number"
                        required
                        v-model="submission.contentInsurance.insurance_sum"
                        step="1000"
                        @change="roundToThousands(submission.contentInsurance.insurance_sum, 'content')"
              />
              <span class="md-error">Bitte geben Sie hier die Summe an.</span>
            </md-field>
<!--            <md-radio v-model="submission.contentInsurance.insurance_sum" :value="50000">50.000 EUR</md-radio>-->
<!--            <md-radio v-model="submission.contentInsurance.insurance_sum" :value="100000">100.000 EUR</md-radio>-->

          </div>

          <!-- Previous insurance -->
          <div v-if="submission.contentInsurance.insurance_type">

            <p class="holder">Besteht bereits eine Versicherung?</p>

            <md-radio v-model="submission.contentInsurance.previous_insurance" :value="true">Ja</md-radio>
            <md-radio v-model="submission.contentInsurance.previous_insurance" :value="false">Nein</md-radio>

            <md-datepicker v-model="submission.contentInsurance.insurance_begin"
                           v-if="!submission.contentInsurance.previous_insurance"
                           md-immediately
                           :md-disabled-dates="disabledDates"
            >
              <label>Gewünschter Vertragsbeginn *</label>
            </md-datepicker>

            <div v-if="submission.contentInsurance.previous_insurance">

              <p class="holder">Sollen wir Ihren vorhergehenden Vertrag für Sie kündigen?</p>

              <md-radio v-model="submission.contentInsurance.previous_insurance_terminate" :value="true">Ja</md-radio>
              <md-radio v-model="submission.contentInsurance.previous_insurance_terminate" :value="false">Nein
              </md-radio>

              <div v-if="submission.contentInsurance.previous_insurance_terminate">

                <md-field>
                  <label>Versicherungsgesellschaft</label>
                  <md-input v-model="submission.contentInsurance.previous_insurance_company" required/>
                </md-field>

                <md-field>
                  <label>Vertragsnummer</label>
                  <md-input v-model="submission.contentInsurance.previous_insurance_contract_number" required/>
                </md-field>

                <md-datepicker v-model="submission.contentInsurance.previous_insurance_termination_date" md-immediately
                               :md-disabled-dates="disabledDates">
                  <label>Auslaufdatum der Versicherung *</label>
                </md-datepicker>

              </div>

            </div>
          </div>
        </div>

        <!-- Next button -->
        <md-button
            class="md-primary margin-0 md-raised"
            @click="setDone('first', 'second')"
            :disabled="!submission.assetLiability.insurance_type && !submission.contentInsurance.insurance_type && !submission.businessLiability.insurance_type">
          Weiter
        </md-button>

      </md-step>

      <md-step id="second" :md-done="second" md-label="Allgemeine Informationen">
        <!-- Company name -->
        <md-field>
          <md-icon>business</md-icon>
          <label>Firmierung</label>
          <md-input
              type="text"
              required
              v-model="submission.general.company_name"
          />
        </md-field>
        <!-- First name -->
        <md-field>
          <md-icon>person</md-icon>
          <label>Vorname</label>
          <md-input
              type="text"
              required
              v-model="submission.general.first_name"
          />
        </md-field>
        <!-- Last name -->
        <md-field>
          <md-icon>person</md-icon>
          <label>Nachname</label>
          <md-input
              type="text"
              required
              v-model="submission.general.last_name"
          />
        </md-field>
        <!-- Postal code -->
        <md-field>
          <md-icon>place</md-icon>
          <label>Postleitzahl</label>
          <md-input
              type="number"
              required
              v-model="submission.general.postal_code"
          />
          <span class="md-error">Bitte geben Sie eine gültige Postleitzahl an!</span>
        </md-field>
        <!-- Location -->
        <md-field>
          <md-icon>location_city</md-icon>
          <label>Ort</label>
          <md-input
              type="text"
              required
              v-model="submission.general.location"
          />
        </md-field>
        <!-- Street -->
        <md-field>
          <md-icon>apartment</md-icon>
          <label>Straße</label>
          <md-input type="text" required v-model="submission.general.street"/>
        </md-field>
        <!-- House number -->
        <md-field>
          <md-icon>123</md-icon>
          <label>Hausnummer</label>
          <md-input
              type="text"
              required
              v-model="submission.general.house_number"
          />
        </md-field>
        <!-- Are addresses identical? -->
        <div>
          <md-checkbox v-model="areAddressesIdentical">Risikoort und postalische Adresse sind identisch</md-checkbox>
        </div>
        <!-- risk location postal code -->
        <md-field v-if="!areAddressesIdentical">
          <md-icon>place</md-icon>
          <label>Postleitzahl Risikoort</label>
          <md-input
              type="number"
              required
              v-model="submission.risk_location.risk_location_postal_code"
          />
        </md-field>
        <!-- risk location -->
        <md-field v-if="!areAddressesIdentical">
          <md-icon>location_city</md-icon>
          <label>Ort Risikoort</label>
          <md-input
              type="text"
              required
              v-model="submission.risk_location.risk_location"
          />
        </md-field>
        <!-- risk location postal code -->
        <md-field v-if="!areAddressesIdentical">
          <md-icon>apartment</md-icon>
          <label>Straße Risikoort</label>
          <md-input
              type="text"
              required
              v-model="submission.risk_location.risk_location_street"
          />
        </md-field>
        <!-- risk location postal code -->
        <md-field v-if="!areAddressesIdentical">
          <md-icon>123</md-icon>
          <label>Hausnummer Risikoort</label>
          <md-input
              type="text"
              required
              v-model="submission.risk_location.risk_location_house_number"
          />
        </md-field>

        <!-- Next button -->
        <md-button
            class="md-primary margin-16-0-0-0 md-raised"
            @click="setDone('second', 'third')"
            v-if="!isFileUploadEnabled"
        >
          Weiter
        </md-button>

        <!-- Next button -->
        <md-button
            class="md-primary margin-16-0-0-0 md-raised"
            @click="setDone('second', 'fourth')"
            v-else
        >
          Weiter
        </md-button>

      </md-step>

      <md-step
          id="third"
          :md-done="third"
          md-label="Maklervollmacht"
          :md-description="isFileUploadEnabled ? 'Optional' : 'Erforderlich'"
          v-if="!isFileUploadEnabled"
      >

        <!-- broker authorization -->
        <p class="bolder">Bitte laden Sie die Maklervollmacht herunter und unterschreiben Sie diese.</p>

        <md-button class="md-primary button-adjuster margin-0" @click="generatePDF" :disabled="isFileUploadEnabled">
          Maklervollmacht herunterladen
        </md-button>

        <!-- Upload file -->
        <p class="bolder">Bitte laden Sie im Anschluss die unterschriebene Maklervollmacht hoch.</p>

        <md-field>
          <label>Maklervollmacht hochladen</label>
          <md-file @md-change="uploadFile" :disabled="isFileUploadEnabled"/>
        </md-field>

        <!-- Next button -->
        <md-button
            class="md-primary margin-16-0-0-0 md-raised"
            @click="setDone('third', 'fourth')"
        >
          Weiter
        </md-button>

      </md-step>

      <md-step id="fourth" :md-done="fourth" md-label="Abschluss">
        <!-- had previous damages -->
        <md-radio
            class="margin-16-16-0-0"
            v-model="submission.additional.had_previous_damage"
            :value="false"
        >
          Der Versicherungsnehmer bestätigt in den letzten drei Jahren keine
          Schäden gehabt zu haben, die in Summe den Betrag von 2.500 EUR
          übersteigen.
        </md-radio>
        <md-radio
            class="margin-16-16-16-0"
            v-model="submission.additional.had_previous_damage"
            :value="true"
        >
          Der Versicherungsnehmer hatte in den letzten drei Jahren Schäden, die
          in Summe den Betrag von 2.500 EUR übersteigen und bittet um
          Kontaktaufnahme.
        </md-radio>

        <!-- Remax mail -->
        <md-field :class="isWrongEmailClass">
          <md-icon>mail</md-icon>
          <label>Verifizierte Remax E-Mail-Adresse</label>
          <md-input type="email" required v-model="remax_email" @input="checkEmailAddress(remax_email)"/>
          <span class="md-error">
            Bitte geben Sie eine E-Mail-Adresse an, die mit ...@remax.de oder
            ...@remax.com endet
          </span>
        </md-field>
        <md-button
            class="md-primary button-adjuster margin-0-0-16-0"
            @click="handleSendVerification"
            :disabled="isVerificationButtonDisabled || isEmailVerified"
        >
          E-Mail Verifizieren
        </md-button>

        <!-- Has accepted broker authorization -->
        <div class="checkbox-adjuster">
          <md-checkbox
              v-model="submission.additional.has_accepted_broker_authorisation"
              :value="true"
          />
          <div>
            Als Versicherungsmakler sind wir gemäß § 59 Abs. 3
            Versicherungsvertragsgesetz Ihr Interessenvertreter. Insoweit
            benötigen wir Ihre Mandatierung, um den gewünschten
            Versicherungsschutz für Sie zu installieren. Dafür stimmen Sie bitte
            unserer <a @click="generateBrokerAgreement" class="bold-link">Maklervereinbarung</a> zu.
          </div>
        </div>

        <!-- Has accepted data protection -->
        <div class="checkbox-adjuster">
          <md-checkbox
              v-model="submission.additional.has_accepted_data_protection"
              :value="true"
          />
          <div>
            Sie erklären sich damit einverstanden, dass Ihre Daten zur Bearbeitung
            Ihres Anliegens verwendet werden. Informationen und Widerrufshinweise
            finden Sie in unserer <a href="/einwilligung_datenschutz.pdf" target="_blank" class="bold-link">Datenschutzerklärung</a>.
          </div>
        </div>

        <!-- Consultation documentation -->
        <div class="checkbox-adjuster">
          <md-checkbox
              :value="true"
              v-model="submission.additional.has_accepted_consultation_documentation"
          />
          <div>
            Sie haben die
            <a @click="generateConsultationDocumentation" class="bold-link">Beratungsdokumentation</a>
            gelesen.
          </div>
        </div>

        <!-- Confirmation to contact -->
        <div class="checkbox-adjuster">
          <md-checkbox
              :value="true"
              v-model="submission.additional.has_accepted_contact_consent"
          />
          <div>
            Sie geben hiermit die
            <a @click="generateContactAuthorization" class="bold-link">Einwilligung zur
              Kontaktaufnahme</a>.
          </div>
        </div>

        <!-- Send button -->
        <md-button
            class="md-primary md-raised width-100 margin-16-0-0-0"
            @click="uploadSubmission"
            :disabled="!isEmailVerified"
        >
          Versicherungsvertrag abschließen
        </md-button>
      </md-step>

    </md-steppers>

    <!-- Snackbars -->
    <md-snackbar :md-active.sync="isError">
      {{ errorMessage }}
    </md-snackbar>
    <md-snackbar :md-active.sync="isSuccess">
      {{ successMessage }}
    </md-snackbar>

    <!-- Dialogs -->
    <md-dialog :md-active="showVerificationDialog">
      <md-dialog-title>Bitte verifizieren Sie Ihre E-Mail-Adresse</md-dialog-title>
      <md-dialog-content>
        <p>Sie haben einen Code per E-Mail zugesendet bekommen, mit welchem Sie sich verifizieren können.</p>
        <md-field>
          <label>Verifikationscode</label>
          <md-input v-model="verificationCode" @keydown.enter="handleVerifyVerification"/>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="handleVerifyVerification">E-Mail Verifizieren</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active="showSuccessDialog">
      <md-dialog-title>Vielen Dank für Ihren Abschluss</md-dialog-title>
      <md-dialog-content>Danke für Ihren Abschluss. Sie haben als Bestätigung eine E-Mail mit den Erfassten Daten erhalten.</md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" href="https://www.gutes-onlinemarketing.de/remax-dgvo/">Zurück zur Startseite</md-button>
      </md-dialog-actions>
    </md-dialog>

  </div>
</template>

<script>
import errorMixin from "../mixins/errorMixin";
import successMixin from "../mixins/successMixin";
import emailCheckMixin from "../mixins/emailCheckMixin";
import activeStepsMixin from "../mixins/activeStepsMixin";
import disabledDatesMixin from "../mixins/disabledDatesMixin";
import PDFGenerator from "../services/pdf/pdfGenerator";
import {
  createSubmission,
  createVerification,
  getIPAddress,
  uploadFile,
  validateVerification,
} from "../services/api";

export default {
  name: "SubmissionForm",
  mixins: [
    errorMixin,
    successMixin,
    emailCheckMixin,
    disabledDatesMixin,
    activeStepsMixin,
  ],
  data() {
    return {
      isEmailVerified: false,
      showVerificationDialog: false,
      showSuccessDialog: false,
      verificationCode: null,
      loading: false,
      remax_email: "",
      showDialog: false,
      areAddressesIdentical: false,
      IPAddress: null,
      submission: {
        general: {
          company_name: null,
          first_name: null,
          last_name: null,
          postal_code: null,
          location: null,
          street: null,
          house_number: null,
        },
        assetLiability: {
          insurance_type: null,
          insurance_sum: 0,
          insurance_revenue: 500000,
          insurance_begin: null,
          previous_insurance: false,
          previous_insurance_terminate: false,
          previous_insurance_company: null,
          previous_insurance_contract_number: null,
          previous_insurance_termination_date: null,
        },
        businessLiability: {
          insurance_type: null,
          insurance_sum: 0,
          insurance_revenue: null,
          insurance_begin: null,
          previous_insurance: false,
          previous_insurance_terminate: false,
          previous_insurance_company: null,
          previous_insurance_contract_number: null,
          previous_insurance_termination_date: null,
        },
        contentInsurance: {
          insurance_type: null,
          insurance_sum: 0,
          insurance_revenue: null,
          insurance_begin: null,
          previous_insurance: false,
          previous_insurance_terminate: false,
          previous_insurance_company: null,
          previous_insurance_contract_number: null,
          previous_insurance_termination_date: null,
        },
        insurance: {
          termination_authorisation_path: null,
        },
        risk_location: {
          risk_location: null,
          risk_location_postal_code: null,
          risk_location_street: null,
          risk_location_house_number: null,
        },
        additional: {
          had_previous_damage: null,
          has_accepted_broker_authorisation: null,
          has_accepted_data_protection: null,
          has_accepted_consultation_documentation: null,
          has_accepted_contact_consent: null,
        },
      },
    };
  },
  watch: {
    areAddressesIdentical(currentValue) {
      if (currentValue) {
        this.submission.risk_location.risk_location_postal_code =
          this.submission.general.postal_code;
        this.submission.risk_location.risk_location =
          this.submission.general.location;
        this.submission.risk_location.risk_location_street =
          this.submission.general.street;
        this.submission.risk_location.risk_location_house_number =
          this.submission.general.house_number;
      }
    },
  },
  computed: {
    isFileUploadEnabled() {
      return (
        !this.submission.assetLiability.previous_insurance_terminate &&
        !this.submission.businessLiability.previous_insurance_terminate &&
        !this.submission.contentInsurance.previous_insurance_terminate
      );
    },
    isWrongEmailClass() {
      return this.isFailedEmailCheck ? "md-invalid" : "";
    },
    isAssetLiabilityActive() {
      return (
        this.submission.assetLiability.insurance_type ===
        "Vermögensschadenshaftpflicht-Versicherung"
      );
    },
  },
  async created() {
    let res = await getIPAddress();
    this.IPAddress = res.data.ip;
  },
  methods: {
    toggleSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
    },
    getDateNow() {
      const date = new Date(Date.now());
      return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join(
        "."
      );
    },
    getPDFGenerator() {
      return new PDFGenerator(
        this.submission.general.company_name,
        this.submission.general.first_name,
        this.submission.general.last_name,
        this.submission.general.street,
        this.submission.general.house_number,
        this.submission.general.postal_code,
        this.submission.general.location
      );
    },
    generateConsultationDocumentation() {
      let pdf = this.getPDFGenerator();
      let selected = [];
      if (
        this.submission.assetLiability.insurance_type ===
        "Vermögensschadenshaftpflicht-Versicherung"
      )
        selected.push("0");
      if (
        this.submission.businessLiability.insurance_type ===
        "Betriebshaftpflichtversicherung"
      )
        selected.push("1");
      if (
        this.submission.contentInsurance.insurance_type ===
        "Inhaltsversicherung mit Ertragsausfall"
      )
        selected.push("2");
      pdf.generateConsultationDocumentation(selected.join(""));
    },
    generateContactAuthorization() {
      let pdf = this.getPDFGenerator();
      pdf.generateContactConsent();
    },
    generateBrokerAgreement() {
      let pdf = this.getPDFGenerator();
      pdf.generateBrokerAgreement(this.getDateNow());
    },
    generatePDF() {
      let pdf = this.getPDFGenerator();
      pdf.generateBrokerAuthorization();
    },
    async uploadFile(files) {
      let file = files[0];
      let formData = new FormData();
      formData.append("file", file);
      try {
        let response = await uploadFile(formData);
        // prettier-ignore
        this.submission.insurance.termination_authorisation_path = response.data.filename;
        this.toggleSuccess("Maklervollmacht wurde erfolgreich hochgeladen!");
      } catch (error) {
        console.error(error);
        this.toggleError(
          "Fehler beim hochladen. Bitte versuchen Sie es später noch einmal."
        );
      }
    },
    getInsurances() {
      let insurances = [
        this.submission.assetLiability,
        this.submission.businessLiability,
        this.submission.contentInsurance,
      ];
      return insurances.filter((item) => {
        return item.insurance_type;
      });
    },
    async uploadSubmission() {
      this.toggleLoading();
      let new_submission = {
        remax_email: this.remax_email,
        ip_address: this.IPAddress,
        ...this.submission.general,
        ...this.submission.insurance,
        ...this.submission.risk_location,
        ...this.submission.additional,
      };
      let selected_insurances = this.getInsurances();
      try {
        await createSubmission({ new_submission, selected_insurances });
        this.toggleSuccessDialog();
        this.toggleLoading();
      } catch (error) {
        console.error(error);
        this.toggleError(
          "Fehler beim versenden. Bitte versuchen Sie es später noch einmal."
        );
        this.toggleLoading();
      }
    },
    async handleSendVerification() {
      try {
        this.toggleVerificationDialog();
        await createVerification(this.remax_email);
      } catch (e) {
        console.error(e);
        this.toggleError(
          "Verifikation fehlgeschlagen. Bitte versuchen Sie es später noch einmal."
        );
      }
    },
    async handleVerifyVerification() {
      try {
        let response = await validateVerification(
          this.remax_email,
          this.verificationCode
        );
        if (response.data.is_valid) {
          this.isEmailVerified = true;
          this.toggleVerificationDialog();
        } else {
          this.toggleError(
            "E-Mail oder Verifikations-Code stimmen nicht überein!"
          );
        }
      } catch (e) {
        console.error(e);
        this.toggleError(
          "Verifikation fehlgeschlagen. Bitte versuchen Sie es später noch einmal."
        );
      }
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
    toggleVerificationDialog() {
      this.showVerificationDialog = !this.showVerificationDialog;
    },
    calculatePremiums() {
      switch (this.submission.assetLiability.insurance_sum) {
        case 250000:
          if (this.submission.assetLiability.insurance_revenue === 500000)
            return "225,00 €";
          if (this.submission.assetLiability.insurance_revenue === 1000000)
            return "320,00 €";
          if (this.submission.assetLiability.insurance_revenue === 1000001)
            return "510,00 €";
          break;
        case 500000:
          if (this.submission.assetLiability.insurance_revenue === 500000)
            return "330,00 €";
          if (this.submission.assetLiability.insurance_revenue === 1000000)
            return "400,00 €";
          if (this.submission.assetLiability.insurance_revenue === 1000001)
            return "665,00 €";
          break;
        case 1000000:
          if (this.submission.assetLiability.insurance_revenue === 500000)
            return "440,00 €";
          if (this.submission.assetLiability.insurance_revenue === 1000000)
            return "660,00 €";
          if (this.submission.assetLiability.insurance_revenue === 1000001)
            return "935,00 €";
          break;
        case 1500000:
          if (this.submission.assetLiability.insurance_revenue === 500000)
            return "550,00 €";
          if (this.submission.assetLiability.insurance_revenue === 1000000)
            return "690,00 €";
          if (this.submission.assetLiability.insurance_revenue === 1000001)
            return "1900,00 €";
          break;
        default:
          return "n.A.";
      }
    },
    roundToThousands(value, type) {
      let rounded = Math.round(value / 1000) * 1000;
      if (type === "content")
        this.submission.contentInsurance.insurance_sum = rounded;
      if (type === "asset")
        this.submission.assetLiability.insurance_sum = rounded;
    },
  },
};
</script>

<style scoped lang="scss">
.rel-container {
  position: relative;
}

.spinner-adjuster {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: translate(50%, -50%);
  right: translate(50%, -50%);
}

.opacity-30 {
  filter: opacity(0.3);
  pointer-events: none;
}

.bolder {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.margin-0 {
  margin: 0;
}

.margin-16-0-0-0 {
  margin: 16px 0 0 0;
}

.margin-0-0-16-0 {
  margin: 0 0 16px 0 !important;
}

.width-100 {
  width: 100%;
}

// Radio Group
.margin-16-16-0-0 {
  margin: 16px 16px 0 0 !important;
}

.margin-16-16-16-0 {
  margin: 16px 16px 16px 0 !important;
}

// Checkbox
.checkbox-adjuster {
  display: flex;
  align-items: center;
}

.email-verification-wrapper {
  position: relative;

  &-button {
    position: absolute;
    top: 12px;
    right: 0;
  }
}

.insurance-wrapper {
  margin-bottom: 36px;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.button-adjuster {
  background-color: #e8ebf0 !important;
}

.f-16 {
  font-size: 16px;
}

.bold-link {
  font-weight: bold;
}

.info-text {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  border-left: 4px solid #ffd600;
  background-color: rgba(255, 214, 0, 0.1);
  padding: 8px;
}
</style>
